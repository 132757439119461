import { Routes, Route } from "react-router-dom"

import { NotFound } from "../Pages/404";
import { AffectedObjectApplications } from "../Pages/AffectedObjectApplications";
import { BackofficeIndustrialUnit } from "../Pages/Backoffice/IndustrialUnit";
import { IncidentManagement } from "../Pages/IncidentManagement";
import { BackofficeObjectApplication } from "../Pages/Backoffice/ObjectApplications";
import { BackofficeProduct } from "../Pages/Backoffice/Product";
import { BackofficeObjectApplicationProduct } from "../Pages/Backoffice/ObjectApplicationProducts";

export function AppRoutes() {
	return (
		<Routes>
			<Route path="/" element={<AffectedObjectApplications />} />
			<Route path="/manage/incident" element={<IncidentManagement />} />
			<Route path="/manage/companies" element={<BackofficeIndustrialUnit />} />
			<Route path="/manage/services" element={<BackofficeObjectApplication />} />
			<Route path="/manage/applications" element={<BackofficeProduct />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}