import { useEffect, useState } from "react";
import { Box, Container, Icon, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import { FiInbox } from "react-icons/fi"

import { Navigation } from "../../Components/Navigation";
import { getAffectedPoducts } from "../../Api/AffetedProducts";
import { IAffectedObjectApplications } from "../../Components/AffectedObjectApplicationsCard/interface";
import { AffectedObjectApplicationsCard, SkeletonAffectedObjectApplicationsCard } from "../../Components/AffectedObjectApplicationsCard";
import { useAuth } from "../../Hook/useAuth";

export function AffectedObjectApplications(){
    const { accessToken } = useAuth()
    const toast = useToast()

    const [affectedObjectApplications, setAffectedObjectApplications] = useState([])
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        const loadAffectedObjectApplications = async(token: string) => {
            try {
                const response = await getAffectedPoducts(token)
                if(response.status === 200) {
                    setAffectedObjectApplications(response.data)
                } else {
                    toast({
                        title: 'Serviços Afetados!',
                        description: "Erro ao carregar serviços afetados do servidor!",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }                
            } catch (error) {
                toast({
                    title: 'Serviços Afetados!',
                    description: "Erro ao carregar serviços afetados do servidor!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            } finally {
                setLoading(false)
            }
        }

        if(accessToken) {
            loadAffectedObjectApplications(accessToken)
        }

        // eslint-disable-next-line
    }, [accessToken])

    const FAKE_ARRAY = [1,2,3,4,5,6]

    return(
        <Container maxW='container.xl' p={8} height="100vh">
            <Navigation showSettingsButton={true} />

            <SimpleGrid minChildWidth='300px' gap={8}>
                {loading 
                    ? FAKE_ARRAY.map((it, index) => <SkeletonAffectedObjectApplicationsCard key={`${it}-${index}`} />)
                    : affectedObjectApplications.map((row: IAffectedObjectApplications, index: number) => <AffectedObjectApplicationsCard key={`${row.id}-${index}`} affectedObjectApplication={row} />)
                }
            </SimpleGrid >

            {!loading && affectedObjectApplications.length === 0 ? (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" height="90%">                
                    <Icon w={16} h={16} as={FiInbox} color="blackAlpha.600" />
                    <Text fontSize="lg" color="blackAlpha.600">Sem dados para mostrar</Text>
                </Box>
            ) : null}
            
        </Container>
    )
}