import {
    Avatar, Badge,
    Box,
    Divider,
    Flex,
    SkeletonCircle,
    SkeletonText,
    StackDivider,
    Text,
    Tooltip,
    useColorMode,
    VStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon
} from "@chakra-ui/react";

import { IAffectedObjectApplicationsCardProps } from "./interface";
import { ICommonProps } from "../../Pages/IncidentManagement/IncidentForm/interface";

export function AffectedObjectApplicationsCard({ affectedObjectApplication }: IAffectedObjectApplicationsCardProps) {
    const { colorMode } = useColorMode()
    //OLD_NAME -> services
    const {
        incidentCreationDate,
        incidentExpectedResolutionDate,
        incidentImpactLevel,
        companyNames,
        name,
        services,
        logoUrl,
        reason
    } = affectedObjectApplication

    let objectApplicationsToBeShowns = services
    let objectApplicationsToBeHiddens: ICommonProps[] = []
    if (services.length > 4) {
        objectApplicationsToBeShowns = services.slice(0, 4)
        objectApplicationsToBeHiddens = services.slice(4, services.length)
    }
    //OLD_NAME -> services
    return (
        <Box w="100%" borderWidth='1px' borderRadius='md' p="4">
            <VStack
                divider={<StackDivider />}
                spacing={4}
                align='stretch'
            >
                <Accordion allowToggle>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'>
                                    <VStack spacing={2} align='center'>
                                        <Avatar w={86} h={86} name='Software' src={logoUrl} />
                                        <Text fontWeight="600" fontSize="18">{name}</Text>

                                        <Box display="flex" flexDirection="row" justifyContent="center"
                                            alignItems="center">
                                            <Badge
                                                colorScheme="orange"
                                                px="4"
                                                py="1"
                                                borderRadius="12"
                                                // The condition to determine the background color of the Badge component
                                                background={incidentImpactLevel === "Indisponibilidade Total" ? "red.400" : "orange.400"}
                                            >
                                                <Text color="black">
                                                    {incidentImpactLevel}
                                                </Text>
                                            </Badge>

                                        </Box>
                                    </VStack> </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <VStack spacing={2} align='stretch'>

                                <Flex justify="space-between">
                                    <Text color="#000000" fontWeight="700" fontSize="15">Data expectável de resolução:</Text>
                                    <Text fontWeight="400" fontSize="14">{incidentExpectedResolutionDate?.split("T")?.[0]}</Text>
                                </Flex>
                            </VStack>

                            <VStack spacing={2} align='stretch'>
                                <Box>
                                    <Text color="#000000" fontWeight="700" fontSize="15">Motivo:</Text>
                                    <Text fontWeight="450" fontSize="14">{reason?.split("T")?.[0]}</Text>
                                </Box>
                            </VStack>

                            <VStack spacing={2} align='start'>
                                <Text fontWeight="600" fontSize="16">Impactado por:</Text>
                                <Flex alignItems="center">
                                    <Box mr="2" p="2" w="12" h="12" display="flex" justifyContent="center"
                                        alignItems="center" borderRadius="full"
                                        bg={colorMode === 'light' ? 'black' : 'white'}>
                                        <Text fontWeight="800" fontSize="18"
                                            color={colorMode === 'light' ? 'white' : 'black'}>{services.length}</Text>
                                    </Box>
                                    <Tooltip label={objectApplicationsToBeHiddens.map(item => item.name)?.join(", ") as string}>
                                        <Text fontWeight="400"
                                            fontSize="14">{objectApplicationsToBeHiddens.length ? `${objectApplicationsToBeShowns.map(item => item.name)?.join(", ")}, ...` : objectApplicationsToBeShowns.map(item => item.name)?.join(", ")}</Text>
                                    </Tooltip>
                                </Flex>

                            </VStack>
                            <Accordion allowToggle>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex='1' textAlign='left'>
                                            <VStack spacing={2} align='center'>
                                                <Text fontWeight="600" fontSize="18">Unidades Industriais afetadas🔍</Text>
                                            </VStack> </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                {companyNames.split("~").map((unidadeName) => {
                                    return (<AccordionPanel pb={4}>
                                    <Flex justify="flex-start">
                                        <li/>
                                        <Text fontWeight="400"
                                            fontSize="14">{unidadeName.replace("{","").replace("}","")}</Text>
                                            
                                    </Flex>
                                </AccordionPanel>)
                                })}
                            </AccordionItem>
                        </Accordion>
                        </AccordionPanel>
                    </AccordionItem>


                </Accordion>


            </VStack>
        </Box>
    )
}


// FOR DISPLAY WHILE LOADING DATA
export const SkeletonAffectedObjectApplicationsCard = () => (
    <Box w="100%" borderWidth='1px' borderRadius='md' p="4">
        <VStack
            spacing={4}
            align='stretch'
        >
            <SkeletonCircle alignSelf="center" size='86' />
            <SkeletonText alignSelf="center" width="36" mt='4' noOfLines={1} spacing='4' />
            <SkeletonText alignSelf="center" width="36" mt='4' noOfLines={1} spacing='4' />

            <Divider />

            <SkeletonText mt='4' noOfLines={1} spacing='4' />
            <SkeletonText mt='4' noOfLines={1} spacing='4' />

            <Divider />

            <SkeletonText mt='4' width="24" noOfLines={1} spacing='4' />

            <Flex direction="row">
                <SkeletonCircle size='10' />
                <SkeletonText mt='4' noOfLines={1} spacing='4' />
            </Flex>
        </VStack>
    </Box>
)