import { 
    Button, 
    Checkbox, 
    Drawer, 
    DrawerBody, 
    DrawerCloseButton, 
    DrawerContent, 
    DrawerFooter, 
    DrawerHeader, 
    DrawerOverlay, 
    FormControl, 
    FormLabel, 
    Radio, 
    RadioGroup, 
    Stack, 
    Text, 
    Textarea,
    useToast, 
} from "@chakra-ui/react";
import Select from 'react-select'
import { useFormik } from "formik";
import DatePicker from 'react-date-picker';
import { useEffect, useState } from "react";
import { FiSave, FiTrash2, FiX, FiCalendar } from 'react-icons/fi'


import { 
    deleteIncident,
    getIndustrialUnits, 
    getIndustrialUnitsNames,
    getIncidentById, 
    getIncidentImpactLevels,
    getProducts,
    getObjectApplicationsByProduct,
    postIncident, 
    updateIncident
} from "../../../Api/IncidentManagement";
import { IIncidentFormProps, ICommonProps } from "./interface";
import { ICreateIncident } from "../../../Api/IncidentManagement/interface";

import './styles.css'
import { useAuth } from "../../../Hook/useAuth";


export function IncidentForm({ isOpen, setIsOpen, handleReloadTable, incidentToEdit, statusData }: IIncidentFormProps) {
    const [loading, setLoading] = useState(false)
    const toast = useToast()   
    const { accessToken } = useAuth() 

    const formik = useFormik({
        initialValues: {
            reason: "",
            expectedResolutionDate: null,
            impactLevelId: 0,
            servicesId: [],
            applicationId: [],
            companyIds: [],
            statusId: 0,
            sla: false,
            planned: false
        },
        validate: values => {
            let errors = {} as any

            if(!values.impactLevelId) {
                errors.impactLevelId = "Este campo é obrigatório"
            }

            if(!values.expectedResolutionDate) {
                errors.expectedResolutionDate = "Este campo é obrigatório"
            }

            if(!values.companyIds.length) {
                errors.companyIds = "Este campo é obrigatório"
            }

            if(!values.applicationId) {
                errors.applicationId = "Este campo é obrigatório"
            }

            if(values.sla == null || values.sla == undefined) {
                errors.reason = "Este campo é obrigatório"
            }

            if(values.planned == null || values.planned == undefined) {
                errors.reason = "Este campo é obrigatório"
            }

            return errors;
        },
        onSubmit: async (values, { setSubmitting }) => {
            debugger;
            if(loading) {
                return
            }

            let payload = {} as ICreateIncident
            Object.entries(values).forEach(([key, value]) => {
                if(value) {
                    payload = {
                        ...payload,
                        [key]: value
                    }
                }
            })

            payload = {
                ...payload,
                servicesId: values.servicesId.map((row: any) => row.value).toString(),
                companyIds: values.companyIds.map((row: any) => row.value)
            }

            try {
                let response = null
                if(incidentToEdit) {
                    response = await updateIncident({incidentId: incidentToEdit, data: payload}, accessToken)
                } else {
                    response = await postIncident(payload, accessToken)
                }
                
                if(response.status === 200) {
                    formik.resetForm()
                    handleReloadTable()

                    toast({
                        title: 'Incidente',
                        description: "Operação bem sucedida!",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: 'Incidente',
                        description: "Ocorreu um erro no registo de incidência!",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch (error) {
                toast({
                    title: 'Incidente',
                    description: "Ocorreu um erro no registo de incidência!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            } finally {
                setSubmitting(false)
            }            
        },
    })

    const [product, setProduct] = useState([])
    const [industrialUnit, setIndustrialUnit] = useState([])
    const [incidentImpactLevel, setIncidentImpactLevel] = useState([])
    const [objectApplicationsByProduct, setObjectApplicationsByProduct] = useState([])

    const loadObjectApplicationsByProduct = async(applicationId: number, token: string) => {
        try {
            const response = await getObjectApplicationsByProduct(applicationId, token)
            if(response.status === 200) {
                const options= response.data.map((row: ICommonProps) => ({ value: row.id, label: row.name}))
                setObjectApplicationsByProduct(options)
                return options as any[];
            } else {
                toast({
                    title: 'Serviços',
                    description: "Ocorreu ao ler dados dos objetos aplicacionais",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Serviços',
                description: "Ocorreu ao ler dados dos objetos aplicacionais",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const handleDeleteIncident = async(incidentId: number, token: string) => {
        try {
            setLoading(true)
            const response = await deleteIncident(incidentId, token)

            if(response.status === 200) {
                formik.resetForm()                
                handleReloadTable()

                toast({
                    title: 'Incidente',
                    description: "O registo do incidente foi eliminado com sucesso!",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: 'Incidente',
                    description: "Ocorreu erro ao eliminar incidente!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Incidente',
                description: "Ocorreu erro ao eliminar incidente!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            setLoading(false)
        }
    }

    const loadIncidentById = async(id: number, token: string) => {
        try {
            const response = await getIncidentById(id, token, true)

            if(response.status === 200) {

                const {
                    applicationId,
                    reason,
                    companyIds,
                    expectedResolutionDate,
                    impactLevelId,
                    servicesId,
                    statusId,
                    sla,
                    planned
                } = response.data
                debugger;

                let objectApplicationByProductResponse: any[] = []
                if(applicationId) {
                    objectApplicationByProductResponse = await loadObjectApplicationsByProduct(applicationId, token) as any[]
                }    

                const objectApplications = objectApplicationByProductResponse?.filter((row: any) => servicesId?.includes(row.value))
                const industrialUnits = industrialUnit.filter((row: any) => companyIds?.includes(row.value))

                await formik.setFieldValue("reason", reason)
                await formik.setFieldValue("expectedResolutionDate", new Date(expectedResolutionDate))
                await formik.setFieldValue("impactLevelId", impactLevelId)
                await formik.setFieldValue("servicesId", objectApplications)
                await formik.setFieldValue("applicationId", applicationId)
                await formik.setFieldValue("companyIds", industrialUnits)
                await formik.setFieldValue("statusId", statusId)
                await formik.setFieldValue("sla", sla)
                await formik.setFieldValue("planned", planned)
            } else {
                toast({
                    title: 'Incidente',
                    description: "Ocorreu erro ao carregar incidente!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Incidente',
                description: "Ocorreu erro ao carregar incidente!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    useEffect(() => {
        if(incidentToEdit && accessToken) {
            loadIncidentById(incidentToEdit, accessToken)
        }

        // eslint-disable-next-line
    }, [incidentToEdit, accessToken])

    useEffect(() => {

        const loadDataFromImpactLevelAndLocation = async(token: string) => {
            try {
                const impactLevels = await getIncidentImpactLevels(token)
                const products = await getProducts(token)
                const industrialUnits = await getIndustrialUnitsNames(token)

                if(impactLevels.status === 200) {
                    const incidentDropDownOptions= impactLevels.data.map((row: ICommonProps) => ({ value: row.id, label: row.name}))
                    setIncidentImpactLevel(incidentDropDownOptions)
                }

                if(industrialUnits.status === 200) {
                    const industrialUnitsDropDownOptions= industrialUnits.data.map((row: ICommonProps) => ({ value: row.id, label: row.name}))
                    setIndustrialUnit(industrialUnitsDropDownOptions)
                }

                if(products.status === 200) {
                    const productDropDownOptions= products.data.map((row: ICommonProps) => ({ value: row.id, label: row.name}))
                    setProduct(productDropDownOptions)
                }

                if(impactLevels.status !== 200 || industrialUnits.status !== 200 || products.status !== 200) {
                    toast({
                        title: 'Impacto level / Localização / Empresas',
                        description: "Ocorreu ao ler dados!",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch (error) {
                toast({
                    title: 'Impacto level / Localização / Empresas',
                    description: "Ocorreu ao ler dados!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }

        if(accessToken) {
            loadDataFromImpactLevelAndLocation(accessToken)
        }
        // eslint-disable-next-line
    }, [accessToken])

    return(
        <Drawer
            size="xl"
            isOpen={isOpen}
            placement='right'
            onClose={() => {
                if(loading) {
                    return
                }
                formik.resetForm()
                setIsOpen(!isOpen)
            }}            
        >
            <DrawerOverlay />
            <DrawerContent overflowY="scroll">
                <DrawerCloseButton />

                <DrawerHeader borderBottomWidth='1px'>
                    <Text>Serviço principal</Text>
                </DrawerHeader>

                <form onSubmit={formik.handleSubmit}>
                    <DrawerBody>
                        <Stack spacing="4" p="8">
                            
                            <FormControl isRequired>
                                <FormLabel htmlFor="impactLevelId">Nível de Impacto</FormLabel>
                                <Select
                                    id="impactLevelId"
                                    name="impactLevelId"                                    
                                    placeholder="Nível de Impacto"
                                    onChange={({value}: any) => formik.setFieldValue('impactLevelId', value)}
                                    options={incidentImpactLevel}
                                    value={incidentImpactLevel.find((row: any) => row?.value === formik.values.impactLevelId)}
                                />
                                {formik.errors.impactLevelId && !formik.values.impactLevelId ? <Text color="red" fontSize='xs'>{formik.errors.impactLevelId}</Text> : null}
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel htmlFor="expectedResolutionDate">Data Expectável de Resolução</FormLabel>
                                <DatePicker
                                    className="datepicker"
                                    name="expectedResolutionDate"
                                    value={formik.values.expectedResolutionDate} 
                                    onChange={(value: Date) => formik.setFieldValue('expectedResolutionDate', value)} 
                                    format="dd/MM/yyyy"
                                    clearIcon={<FiX />}
                                    dayPlaceholder="dd"
                                    monthPlaceholder="mm"
                                    yearPlaceholder="yyyy"
                                    calendarIcon={<FiCalendar />}
                                />
                                {formik.errors.expectedResolutionDate && !formik.values.expectedResolutionDate ? <Text color="red" fontSize='xs'>{formik.errors.expectedResolutionDate}</Text> : null}
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel htmlFor="companyIds">Unidade Industrial</FormLabel>
                                <Select 
                                    isMulti
                                    id="companyIds"
                                    name="companyIds"
                                    placeholder="Unidades Industriais"
                                    closeMenuOnSelect={false}
                                    options={industrialUnit}
                                    onChange={value => formik.setFieldValue('companyIds', value)}
                                    value={formik.values.companyIds}
                                />
                                {formik.errors.companyIds && !formik.values.companyIds?.length ? <Text color="red" fontSize='xs'>{formik.errors.companyIds}</Text> : null}
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel htmlFor="applicationId">Produtos associados</FormLabel>
                                <Select 
                                    id="applicationId"
                                    name="applicationId"
                                    placeholder="Produtos associados"
                                    options={product}
                                    onChange={({ value }: any) => {
                                        formik.setFieldValue('applicationId', value)

                                        if(value) {
                                            loadObjectApplicationsByProduct(Number(value), accessToken)
                                        }
                                    }}
                                    value={product.find((row: any) => row?.value === formik.values.applicationId)}
                                />
                                {formik.errors.applicationId && !formik.values.applicationId ? <Text color="red" fontSize='xs'>{formik.errors.applicationId}</Text> : null}
                            </FormControl>
                            
                            <FormControl>
                                <FormLabel htmlFor="servicesId">Objetos Aplicacionais</FormLabel>
                                <Select
                                    isMulti
                                    id="servicesId"
                                    name="servicesId"
                                    placeholder="Objetos Aplicacionais"
                                    closeMenuOnSelect={false}
                                    options={objectApplicationsByProduct}
                                    onChange={value => formik.setFieldValue('servicesId', value)}
                                    value={formik.values.servicesId}
                                />
                            </FormControl>

                            {incidentToEdit && (
                                <FormControl>
                                    <FormLabel htmlFor="statusId">Estado</FormLabel>
                                    <Select 
                                        id="statusId"
                                        name="statusId"
                                        placeholder="Estado"
                                        options={statusData}
                                        onChange={({ value }: any) => formik.setFieldValue('statusId', value)}
                                        value={statusData.find((row: any) => row?.value === formik.values.statusId)}
                                    />
                                </FormControl>
                            )}

                            <FormControl>
                                <FormLabel htmlFor="sla">SLA</FormLabel>
                                <RadioGroup 
                                    id="sla"
                                    defaultValue='0'
                                    onChange={(value) => value == '1' ? formik.setFieldValue('sla', true) : formik.setFieldValue('sla', false)}
                                    value={formik.values.sla === true ? '1' : '0'}>
                                        <Stack>
                                            <Radio value='0'>Não</Radio>
                                            <Radio value='1'>Sim</Radio>
                                        </Stack>
                                    </RadioGroup>
                                {formik.errors.sla && !formik.values.sla ? <Text color="red" fontSize='xs'>{formik.errors.sla}</Text> : null}
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="planned">Planeado</FormLabel>
                                <RadioGroup 
                                    defaultValue='0'
                                    id="planned"
                                    onChange={(value) => value == '1' ? formik.setFieldValue('planned', true) : formik.setFieldValue('planned', false)}
                                    value={formik.values.planned === true ? '1' : '0'}>
                                        <Stack>
                                            <Radio value='0'>Não</Radio>
                                            <Radio value='1'>Sim</Radio>
                                        </Stack>
                                    </RadioGroup>
                                {formik.errors.planned && !formik.values.planned ? <Text color="red" fontSize='xs'>{formik.errors.planned}</Text> : null}
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="reason">Motivo</FormLabel>
                                <Textarea
                                    id="reason"
                                    name="reason"
                                    maxLength={1000} 
                                    placeholder='Indique o motivo.'
                                    onChange={formik.handleChange}
                                    value={formik.values.reason}                              
                                />
                            </FormControl>

                        </Stack>
                    
                    </DrawerBody>

                    <DrawerFooter justifyContent="start" px="14">
                        {incidentToEdit ? 
                            <Button isLoading={loading} colorScheme="red" leftIcon={<FiTrash2 />} variant='ghost' mr="4" onClick={() => handleDeleteIncident(incidentToEdit, accessToken)}>
                                Remover incidência
                            </Button>
                        : null}
                        <Button isLoading={formik.isSubmitting} type="submit" colorScheme='blue' leftIcon={<FiSave />}>Guardar incidência</Button>                        
                    </DrawerFooter>
                </form>
            </DrawerContent>
        </Drawer>
    )
}