import { useEffect, useState } from "react";
import { Container, Flex, Box } from "@chakra-ui/react";
import { IndustrialUnitForm } from "./Form";
import { MyTable } from "../../../Components/Table";
import { Pagination } from "../../../Components/Pagination";
import { Navigation } from "../../../Components/Navigation";
import { IPaginationRequest } from "../../../Api/IncidentManagement/interface";
import { IMyTableColumn, IResponse } from "../../../Components/Table/interface";
import { getIndustrialUnitsWithPagination } from "../../../Api/IncidentManagement";
import { useAuth } from "../../../Hook/useAuth";
import { Menu } from "../../../Components/Menu";
import { MENU_KEYS } from "../../../Api/Constants";
import { Header } from "../../../Components/Header/Header";

export function BackofficeIndustrialUnit() {
    const { accessToken } = useAuth()
    const [loading, setLoading] = useState(true); 
    const [currentPage, setCurrentPage] = useState(1)   
    const [reloadTable, setReloadTable] = useState(false);    
    const [id, setIndustrialUnitToEdit] = useState(0); 
    const [data, setData] = useState<IResponse>({} as IResponse)
    const [showIndustrialUnitForm, setShowIndustrialUnitForm] = useState(false);

    const currentMenuKey = MENU_KEYS.IndustrialUnitMenuKey;

    const tableColumns: IMyTableColumn[] = [
        {
            key: "name",
            title: "Nome",
            dataIndex: "name", 
        },
        {
            key: "code",
            title: "Código de unidade industrial",
            dataIndex: "code", 
        }
    ]

    const handleIndustrialUnitForm = () => {
        setShowIndustrialUnitForm(!showIndustrialUnitForm)

        if(id) {
            handleTableRowClick(0)
        }
    }

    const handleTableRowClick = (row: any) => {
        setIndustrialUnitToEdit(row)
        setShowIndustrialUnitForm(!showIndustrialUnitForm)
    }

    const handleReloadTable = () => {
        setReloadTable(!reloadTable)
        setLoading(true)
        handleIndustrialUnitForm()

        if(id) {
            handleTableRowClick(0)
        }
    }

    useEffect(()=>  {
        const loadCompanies = async ({ page, pageSize = 10 }: IPaginationRequest) => {
            try {
                if(!loading) {
                    setLoading(true)
                } 
                
                const response = await getIndustrialUnitsWithPagination({page, pageSize}, accessToken)
                if(response.status === 200) {
                    setData(response.data)
                    setCurrentPage(response.data?.pagination?.currentPageNumber)
                }
            } catch (error) {
            } finally {
                setLoading(false)
            }
        }
        if(accessToken){
            loadCompanies({page: currentPage, pageSize: 10});
        }
    }, [reloadTable, currentPage, accessToken])

    return(
        <Container maxW='container.xl' p={8}>
            <Navigation handleAddAction={handleIndustrialUnitForm} addButtonText="Adicionar Unidade Industrial" showSettingsButton={false} />
            <Flex>
                <Menu activeKey={currentMenuKey}></Menu>
                
                <Box bg='' w="100%" 
                    borderRightWidth='1px'
                    borderTopWidth='1px'
                    borderBottomWidth='1px'
                    borderTopRightRadius='md'
                    borderBottomRightRadius='md'>
                    <Header title="Adicionar ou remover Unidades Industriais"/>

                    <MyTable 
                        data={data} 
                        loading={loading} 
                        columns={tableColumns} 
                        handleTableRowClick={handleTableRowClick}
                    /> 

                    <Box marginLeft={4}>
                        <Pagination 
                            onPageChange={page => setCurrentPage(page)} 
                            currentPage={currentPage} 
                            totalCount={data?.pagination?.pageRecordNumber} 
                            pageSize={data?.pagination?.totalRecords}
                            totalPageCount={data?.pagination?.totalPages}
                            hasNextPage={data?.pagination?.hasNextPage}
                            hasPreviousPage={data?.pagination?.hasPreviousPage}
                        /> 
                    </Box>
                </Box>

                <IndustrialUnitForm 
                    isOpen={showIndustrialUnitForm} 
                    setIsOpen={handleIndustrialUnitForm} 
                    handleReloadTable={handleReloadTable} 
                    id={id} 
                />
            </Flex>
        </Container>
    )
}