import { 
    Box,
    Button, 
    Drawer, 
    DrawerBody, 
    DrawerCloseButton, 
    DrawerContent, 
    DrawerFooter, 
    DrawerHeader, 
    DrawerOverlay, 
    FormControl, 
    FormLabel, 
    Input, 
    Stack, 
    Text, 
    Image,
    useToast, 
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FiSave, FiTrash2 } from 'react-icons/fi'

import { 
    updateProduct,
    postProduct,
    getProductById,
    deleteProduct
} from "../../../Api/IncidentManagement";
import { IFormProps } from "../Common/interfaces";
import { useAuth } from "../../../Hook/useAuth";
import { ICreateProduct } from "./interface";

export function ProductForm({ isOpen, setIsOpen, handleReloadTable, id }: IFormProps) {
    const [loading, setLoading] = useState(false)
    const [showLogoPreview, setShowLogoPreview] = useState(false)
    const [iconUrl, setIconUrl] = useState('')
    const toast = useToast()   
    const { accessToken } = useAuth() 

    const formik = useFormik({
        initialValues: {
            name: '',
            componentId: 0,
            file: []
        },
        validate: values => {
            let errors = {} as any
            if(!values.name) {
                errors.name = "Este campo é obrigatório"
            }

            return errors;
        },
        onSubmit: async (values: ICreateProduct, { setSubmitting }) => {
            if(loading) {
                return
            }

            let payload = {} as ICreateProduct
            Object.entries(values).forEach(([key, value]) => {
                if(value) {
                    payload = {
                        ...payload,
                        [key]: value
                    }
                }
            })
            let data = new FormData();
            data.append("name", payload.name);
            data.append("logo", (values.file[0] || null));

            try {
                let response = null
                if(id) {
                    response = await updateProduct({id: id, data: data}, accessToken)
                } else {
                    response = await postProduct(data, accessToken)
                }
                
                if(response.status === 200) {
                    formik.resetForm()
                    handleReloadTable()

                    toast({
                        title: 'Produto',
                        description: "Operação bem sucedida!",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: 'Produto',
                        description: "Ocorreu um erro no registo de aplicação!",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch (error) {
                toast({
                    title: 'Produto',
                    description: "Ocorreu um erro no registo de aplicação!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            } finally {
                setSubmitting(false)
            }            
        },
    })

    const handleDeleteProduct = async(id: number, token: string) => {
        try {
            setLoading(true)
            const response = await deleteProduct(id, token)

            if(response.status === 200) {
                formik.resetForm()                
                handleReloadTable()

                toast({
                    title: 'Produto',
                    description: "O registo da componente foi eliminado com aplicação!",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: 'Produto',
                    description: "Ocorreu erro ao eliminar aplicação!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Produto',
                description: "Ocorreu erro ao eliminar aplicação!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            setLoading(false)
        }
    }

    const loadProductById = async(id: number, token: string) => {
        try {
            setLoading(true);
            const response = await getProductById(id, token)
            if(response.status === 200) {

                const {
                    name,
                    logoUrl
                } = response.data

                if(logoUrl && logoUrl !== ''){
                    setShowLogoPreview(true);
                    setIconUrl(logoUrl);
                }

                await formik.setFieldValue("name", name)
            } else {
                toast({
                    title: 'Produto',
                    description: "Ocorreu erro ao carregar aplicação!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Produto',
                description: "Ocorreu erro ao carregar aplicação!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        if(id && accessToken) {
            loadProductById(id, accessToken)
        } else if(!id) {
            setShowLogoPreview(false);
        }

        // eslint-disable-next-line
    }, [id, accessToken])

    return(
        <Drawer
            size="xl"
            isOpen={isOpen}
            placement='right'
            onClose={() => {
                if(loading) {
                    return
                }
                formik.resetForm()
                setIsOpen(!isOpen)
                setShowLogoPreview(false);
                setIconUrl('');
            }}>
            <DrawerOverlay />
            <DrawerContent overflowY="scroll">
                <DrawerCloseButton />

                <DrawerHeader borderBottomWidth='1px'>
                    <Text>Produtos</Text>
                </DrawerHeader>

                <form onSubmit={formik.handleSubmit}>
                    <DrawerBody overflow="visible">
                        <Stack spacing="4" p="8">
                            <FormControl isRequired>
                                <FormLabel htmlFor="name">Nome</FormLabel>
                                <Input
                                    id="name"
                                    name="name"
                                    maxLength={1000} 
                                    placeholder='Nome' 
                                    onChange={formik.handleChange}
                                    value={formik.values.name}                              
                                />
                                {formik.errors.name && !formik.values.name ? <Text color="red" fontSize='xs'>{formik.errors.name}</Text> : null}
                            </FormControl>
                            <FormControl isRequired={id ? false : true}>
                                <FormLabel htmlFor="file">Logo <span style={{fontSize: "10px"}}>(Apenas são permitidos ficheiros gif, png, jpeg, jpg)</span></FormLabel>
                                <Input
                                    id="file"
                                    name="file"
                                    placeholder="Logo"
                                    accept=".gif,.png,.jpeg,.jpeg2000,.jpg"
                                    onChange={(event) => {
                                        setShowLogoPreview(false);
                                        const file = event.target.files;
                                        formik.setFieldValue("file", file);
                                      }}
                                    type="file"
                                />
                                {formik.errors.file && !formik.values.file ? <Text color="red" fontSize='xs'>{formik.errors.file}</Text> : null}

                            </FormControl>
                            {showLogoPreview && iconUrl !== "" ? 
                                <Box boxSize="12">
                                    <Image borderRadius="full" src={iconUrl} alt="Logo" />
                                </Box>
                              : null
                            }
                        </Stack>
                    
                    </DrawerBody>

                    <DrawerFooter justifyContent="start" px="14">
                        {id ?
                        <Button isLoading={loading} colorScheme="red" leftIcon={<FiTrash2 />} variant='ghost' mr="4" onClick={() => handleDeleteProduct(id, accessToken)}>
                            Remover Produto
                        </Button>
                        : null }
                        <Button isLoading={formik.isSubmitting} type="submit" colorScheme='blue' leftIcon={<FiSave />}>Guardar</Button>                        
                    </DrawerFooter>
                </form>
            </DrawerContent>
        </Drawer>
    )
}