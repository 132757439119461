import { LinkItemProps } from "../../Components/Menu/interface";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const endpoints = {
    INCIDENT: "Incident",
    INCIDENT_IMPACT_LEVEL: "IncidentImpactLevel",
    INCIDENT_STATUS: "IncidentStatus",

    INDUSTRIAL_UNIT: "Company",

    OBJECT_APPLICATION: "Service",
    OBJECT_APPLICATION_BY_PRODUCT: "Service/GetByProduct",

    PRODUCT: "Application",
    PRODUCT_AFFECTED: "Application/GetAfectedApplications",
    MY_PRODUCTS_AFFECTED: "Application/GetMyAfectedApplications"
}

export const MENU_ITEMS: Array<LinkItemProps> = [
    { name: 'Unidade Industrial', url: '/manage/companies', key: 'companies' },
    { name: 'Produtos', url: '/manage/applications', key: 'applications' },
    { name: 'Objetos Aplicacionais', url: '/manage/services', key: 'services' },
    //{ name: 'Relação Produto / Objetos Aplicacionais', url: '/manage/applicationServices', key: 'applicationServices' },
];

export const MENU_KEYS = {
    IndustrialUnitMenuKey: 'companies',
    LocationMenuKey: 'locations',
    ComponentMenuKey: 'components',
    ObjectApplicationMenuKey: 'services',
    ProductMenuKey: 'applications',
    ObjectApplicationProductMenuKey: 'applicationServices',
}