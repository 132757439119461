import { 
    Button, 
    Drawer, 
    DrawerBody, 
    DrawerCloseButton, 
    DrawerContent, 
    DrawerFooter, 
    DrawerHeader, 
    DrawerOverlay, 
    FormControl, 
    FormLabel, 
    Input, 
    Stack, 
    Text, 
    useToast, 
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FiSave, FiTrash2 } from 'react-icons/fi'


import { 
    deleteIndustrialUnit,
    getIndustrialUnitById, 
    postIndustrialUnit, 
    updateIndustrialUnit, 
} from "../../../Api/IncidentManagement";
import { IFormProps } from "./../Common/interfaces";
import { useAuth } from "../../../Hook/useAuth";
import { ICreateIndustrialUnit } from "./interface";


export function IndustrialUnitForm({ isOpen, setIsOpen, handleReloadTable, id }: IFormProps) {
    const [loading, setLoading] = useState(false)
    const toast = useToast()   
    const { accessToken } = useAuth() 

    const formik = useFormik({
        initialValues: {
            name: '',
            code: ''
        },
        validate: values => {
            let errors = {} as any

            if(!values.name) {
                errors.name = "Este campo é obrigatório"
            }

            if(!values.code) {
                errors.code = "Este campo é obrigatório"
            }

            return errors;
        },
        onSubmit: async (values: ICreateIndustrialUnit, { setSubmitting }) => {

            if(loading) {
                return
            }

            let payload = {} as ICreateIndustrialUnit
            Object.entries(values).forEach(([key, value]) => {
                if(value) {
                    payload = {
                        ...payload,
                        [key]: value
                    }
                }
            })

            try {
                let response = null
                if(id) {
                    response = await updateIndustrialUnit({id: id, data: payload}, accessToken)
                } else {
                    response = await postIndustrialUnit(payload, accessToken)
                }
                
                if(response.status === 200) {
                    formik.resetForm()
                    handleReloadTable()

                    toast({
                        title: 'Unidade Industrial',
                        description: "Operação bem sucedida!",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: 'Unidade Industrial',
                        description: "Ocorreu um erro no registo de unidade industrial!",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch (error) {
                toast({
                    title: 'Unidade Industrial',
                    description: "Ocorreu um erro no registo de unidade industrial!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            } finally {
                setSubmitting(false)
            }            
        },
    })

    const handleDeleteLocation = async(id: number, token: string) => {
        try {
            setLoading(true)
            const response = await deleteIndustrialUnit(id, token)

            if(response.status === 200) {
                formik.resetForm()                
                handleReloadTable()

                toast({
                    title: 'Unidade Industrial',
                    description: "O registo da localização foi eliminado com unidade industrial!",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: 'Unidade Industrial',
                    description: "Ocorreu erro ao eliminar unidade industrial!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Unidade Industrial',
                description: "Ocorreu erro ao eliminar unidade industrial!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            setLoading(false)
        }
    }

    const loadIndustrialUnitById = async(id: number, token: string) => {
        try {
            const response = await getIndustrialUnitById(id, token)

            if(response.status === 200) {

                const {
                    name,
                    code
                } = response.data


                await formik.setFieldValue("name", name)
                await formik.setFieldValue("code", code)

            } else {
                toast({
                    title: 'Unidade Industrial',
                    description: "Ocorreu erro ao carregar unidade industrial!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Unidade Industrial',
                description: "Ocorreu erro ao carregar unidade industrial!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    useEffect(() => {
        if(id && accessToken) {
            loadIndustrialUnitById(id, accessToken)
        }

        // eslint-disable-next-line
    }, [id, accessToken])



    return(
        <Drawer
            size="xl"
            isOpen={isOpen}
            placement='right'
            onClose={() => {
                if(loading) {
                    return
                }
                formik.resetForm()
                setIsOpen(!isOpen)
            }}            
        >
            <DrawerOverlay />
            <DrawerContent overflowY="scroll">
                <DrawerCloseButton />

                <DrawerHeader borderBottomWidth='1px'>
                    <Text>Unidade Industrial</Text>
                </DrawerHeader>

                <form onSubmit={formik.handleSubmit}>
                    <DrawerBody overflow="visible">
                        <Stack spacing="4" p="8">
                            <FormControl isRequired>
                                <FormLabel htmlFor="name">Nome</FormLabel>
                                <Input
                                    id="name"
                                    name="name"
                                    maxLength={1000} 
                                    placeholder='Nome' 
                                    onChange={formik.handleChange}
                                    value={formik.values.name}                              
                                />
                                {formik.errors.name && !formik.values.name ? <Text color="red" fontSize='xs'>{formik.errors.name}</Text> : null}
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel htmlFor="code">Código</FormLabel>
                                <Input
                                    id="code"
                                    name="code"
                                    maxLength={1000} 
                                    placeholder='Código de unidade industrial' 
                                    onChange={formik.handleChange}
                                    value={formik.values.code}                              
                                />
                                {formik.errors.code && !formik.values.code ? <Text color="red" fontSize='xs'>{formik.errors.code}</Text> : null}
                            </FormControl>
                        </Stack>
                    
                    </DrawerBody>

                    <DrawerFooter justifyContent="start" px="14">
                        {id ?
                            <Button isLoading={loading} colorScheme="red" leftIcon={<FiTrash2 />} variant='ghost' mr="4" onClick={() => handleDeleteLocation(id, accessToken)}>
                                Remover unidade industrial
                            </Button>
                        : null }
                        <Button isLoading={formik.isSubmitting} type="submit" colorScheme='blue' leftIcon={<FiSave />}>Guardar</Button>                        
                    </DrawerFooter>
                </form>
            </DrawerContent>
        </Drawer>
    )
}